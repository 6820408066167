import { Port } from '../../types';

export const formatRouteFromPorts = ({
  ports = [],
  textCode,
  textCodePrefix,
} : {
  ports: Port[];
  textCode: string;
  textCodePrefix: string;
}): string => {
  if (!textCode || !ports || !ports.length) {
    return '';
  }

  return ports.map((port) => `${port.name}${port.portCode}`).join(' - ')
    .concat(`: ${textCodePrefix} ${textCode}`);
};
