export const closeOverlay = (element) => {
  element.setAttribute('aria-expanded', 'false');
  element.setAttribute('aria-hidden', true);

  if (element.classList.contains('has-darkOverlay')) {
    element.classList.remove('has-darkOverlay');
  }

  if (element.classList.contains('has-stopScroll')) {
    element.classList.remove('has-stopScroll');
  }

  if (element.classList.contains('panelActive')) {
    element.classList.remove('panelActive');
  }
};
