import gql from 'graphql-tag';

const getPortsServedByPortCodeQuery = gql`
query
($portCode: String)
{
    ports (where: {portCode: {eq: $portCode}})
    {
      name
      description
      portUrl
      address
      routes{
        routeCode
        keyInformation
        polyline
        vessels{
          name
          vesselUrl
          vesselCode
        }
        ports{
          name
          portCode
          portUrl
          longitude
          latitude
          location{
            name
          }
        }
      }
    }
  }
`;

export {
  getPortsServedByPortCodeQuery,
};
