import gql from 'graphql-tag';

const getVesselsServedByVesselCodeQuery = gql`
query
($vesselCode: String)
{
    vessels (where: {vesselCode: {eq: $vesselCode}})
    {
      name
      vesselCode
      routes{
        routeCode
        keyInformation
        polyline
        vessels{
          name
          vesselUrl
          vesselCode
        }
        ports{
          name
          portCode
          portUrl
          longitude
          latitude
          location{
            name
          }
        }
      }
    }
  }
`;

export {
  getVesselsServedByVesselCodeQuery,
};
