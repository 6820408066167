export const DictionaryField = {
  'SMS:Web:AddNewRoute': 'Sms:Web:AddNewRoute',
  'SMS:Web:HowItWorks:Alert:Description': 'Sms:Web:HowItWorks:Alert:Description',
  'SMS:Web:HowItWorks:Alert:Title': 'Sms:Web:HowItWorks:Alert:Title',
  'SMS:Web:HowItWorks:Title': 'Sms:Web:HowItWorks:Title',
  'SMS:Web:HowItWorks:Description': 'Sms:Web:HowItWorks:Description',
  'SMS:Web:NoRoutes:Intro': 'Sms:Web:NoRoutes:Intro',
  'SMS:Web:NoRoutes:Title': 'Sms:Web:NoRoutes:Title',

} as const;

export const dictionaryFieldMap = Object.entries(DictionaryField).reduce<string[]>((agg, curr) => {
  const value = curr[1];
  agg.push(value);
  return agg;
}, []);
