import { createI18n } from 'vue-i18n';
import enGB from './locales/en-GB.json';

type MessageSchema = typeof enGB;

const i18n = createI18n<[MessageSchema], 'en-GB'>({
  legacy: false,
  locale: 'en-GB',
  fallbackLocale: 'en-GB',
  messages: {
    'en-GB': enGB,
  },
});

export default i18n;
