<template>
  <div class="card">
    <slot />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
@use "../../../../scss/references/variables" as *;

.card {
  border: 1px solid $border;
  padding: 8px;
}
</style>
