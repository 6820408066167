import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';

let acquireToken: () => Promise<string | undefined> | undefined;

export const setAcquireToken = (method: () => Promise<string | undefined>) => {
  acquireToken = method;
};

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_CALMAC_API_BASE,
});

// return the headers to the context so httpLink can read them
const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = acquireToken && await acquireToken();

  if (!token) {
    return headers;
  }
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});

export { apolloClient };
