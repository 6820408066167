export const maxBy = <T>(array: T[], iteratee: (item: T) => number | undefined): T | undefined => {
  if (!array.length) {
    return undefined;
  }

  return array.reduce((max, item) => {
    const itemValue = iteratee(item);
    const maxValue = iteratee(max);

    return itemValue && maxValue && itemValue > maxValue ? item : max;
  });
};
