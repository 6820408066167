import { parseISO, subDays } from 'date-fns';
import { RouteInformation, RouteSubscription } from '../types/api';
import { RouteSubscriptionSavedModel } from '../types/sms';

export const mapRouteSubscriptionToSaveModel = ({ item, allRoutes } :
{
  item: RouteSubscription;
  allRoutes: RouteInformation[];
}): RouteSubscriptionSavedModel | undefined => {
  if (!item) {
    return undefined;
  }

  const startDate = parseISO(item.startDate);
  const endDate = subDays(parseISO(item.endDate), 1);

  return {
    id: item.id,
    textCode: item.textCode,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };
};
