import {
  Route, RouteMapCoords,
} from '../types/api';
import {
  mapPolyline, mapPort, sortPorts,
} from '.';
import { GoogleMapsRoute } from '..';

export const mapRoutesToMapRoutes = (item: Route | RouteMapCoords) => (
    {
      name: item.name,
      ports: item.ports?.map(mapPort).sort(sortPorts),
      originPort: {
        name: item.originPort?.name,
        portCode: item.originPort?.portCode,
      },
      destinationPort: {
        name: item.destinationPort?.name,
        portCode: item.destinationPort?.portCode,
      },
      options: {
        path: item?.polyline ? JSON.parse(item.polyline).map(mapPolyline) : [],
        geodesic: true,
        strokeColor: '#000000',
        strokeOpacity: 1,
        strokeWeight: 3,
      },
      code: item.routeCode,
      location: item.location?.name,
    } as GoogleMapsRoute
);
