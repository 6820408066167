import { Ref, ref } from 'vue';
import { defaultValueText } from './defaultValues';
import { dictionaryFieldMap } from './fields';
import { DictionaryResponseItem, DictionaryText } from './types';
import { dictionaryUrl } from '../envVariables';

export const dictionary: Ref<DictionaryText> = ref({
  ...defaultValueText,
});

const updateDictionaryFromResponse = (response: DictionaryResponseItem[]) => {
  response.forEach((entry) => {
    if (entry.key && entry.value) {
      dictionary.value[entry.key] = entry.value;
    }
  });
};

export const createBody = (keys: string[]) => ({
  keys,
  culture: 'en-GB',
});

export const fetchDictionaryValues = async (): Promise<void> => {
  if (!dictionaryUrl) {
    console.log('Error fetching dictionary values. Dictionary url has not been configured.');
    return;
  }
  try {
    const response = await fetch(dictionaryUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(createBody(dictionaryFieldMap)),
    });

    if (!response.ok) {
      console.log(`Error fetching dictionary values: ${response.status}-${response.statusText}`);
      return;
    }

    const dictionaryItems = await response.json() as DictionaryResponseItem[];
    updateDictionaryFromResponse(dictionaryItems);
  } catch (e) {
    console.log('Error fetching dictionary values: ', e);
  }
};
