import { RecentOrSuggestedSearchEntry } from '../types/RenderTypes';
import { TimetableSearch } from '../types/api/index';

export const mapRecentSearches = (item:TimetableSearch) => ({
  iconName: 'clock',
  ports: [
    {
      name: item.from,
    },
    {
      name: item.to,
    }],
  routeCode: item.routeCode,
} as RecentOrSuggestedSearchEntry);
