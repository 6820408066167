import {
  RouteInformation,
} from '../types/api';

import {
  mapPolyline, mapPort, sortPorts,
} from '.';
import { GoogleMapsRoute } from '..';

export const mapRouteByPortsToRoutes = (item: RouteInformation) => (
  {
    name: item.name,
    ports: item.ports?.map(mapPort).sort(sortPorts),
    options: {
      path: item?.polyline ? JSON.parse(item.polyline).map(mapPolyline) : [],
      geodesic: true,
      strokeColor: '#000000',
      strokeOpacity: 1,
      strokeWeight: 3,
    },
  } as GoogleMapsRoute
);
