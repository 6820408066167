import {
  AuthenticationResult, EventType, LogLevel, PublicClientApplication,
} from '@azure/msal-browser';
import { B2CConfig } from './types';
import { getActiveAccount } from './util/token';

// Config object to be passed to Msal on creation
export const buildMsalConfig = (b2cConfig: B2CConfig) => {
  const {
    clientId, tenant, hostname, postLogoutRedirectUri, signInPolicy,
  } = b2cConfig;

  return {
    auth: {
      clientId,
      authority: `https://${hostname}/${tenant}/${signInPolicy}`,
      postLogoutRedirectUri,
      knownAuthorities: [`${hostname}`],
    },
    cache: {
      cacheLocation: 'localStorage',
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              break;
            case LogLevel.Info:
              console.info(message);
              break;
            case LogLevel.Verbose:
              console.debug(message);
              break;
            case LogLevel.Warning:
              console.warn(message);
              break;
            default:
              break;
          }
        },
        logLevel: LogLevel.Verbose,
      },
    },
  };
};

export const createInstance = (b2cConfig: B2CConfig): PublicClientApplication => {
  const config = buildMsalConfig(b2cConfig);

  const msalInstance = new PublicClientApplication(config);
  const accounts = msalInstance.getAllAccounts();
  const activeAccount = getActiveAccount(accounts);

  if (activeAccount) {
    msalInstance.setActiveAccount(activeAccount);
  }
  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const { account } = payload;
      msalInstance.setActiveAccount(account);
    }
  });

  return msalInstance;
};
