import { parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { utcNow } from '../dateTimeUtilities';
import { timezone } from '../../envVariables';

export const getSubscriptionStartDefault = (currentDate = utcNow()): string =>
  toZonedTime(currentDate.toISOString(), timezone).toISOString();

export const getSubscriptionEndDefault = (currentDate = utcNow()): string => {
  currentDate.setUTCDate(currentDate.getUTCDate() - 1);
  currentDate.setUTCMonth(currentDate.getUTCMonth() + 6);
  return toZonedTime(currentDate.toISOString(), timezone).toISOString();
};

export const isValidSubscriptionRange = (startDate?: string, endDate?: string): boolean => {
  if (!startDate || !endDate) {
    return true;
  }
  const start = parseISO(startDate);
  const end = parseISO(endDate);

  return start.getTime() <= end.getTime();
};
