import gql from 'graphql-tag';

const getRoutesWithStatusQuery = gql`
query {
  routes {
    name
    routeCode
    polyline
    location{
      name
    }
    routeStatuses {
      status
      subStatus
    }
    ports {
      name
      portCode
      order
      latitude
      longitude
    }
    status
  }
}
`;

const getRouteInformationQuery = gql`
query 
(
  $originPortName: String
  $destinationPortName: String
) {
  routes 
  (
    where: {
      and: [
          { originPort: {name: { eq: $originPortName } } }
          { destinationPort: {name: { eq: $destinationPortName } } }
      ]
    }
  ) {
    name
    routeInformation
    polyline
    location {
      name
    }
    ports {
      name
      portCode
      order
      latitude
      longitude
    }
  }
}
`;

const getRouteInformationByPortsQuery = gql`
query 
(
  $originPortName: String
  $destinationPortName: String
) {
  routes 
  (
    where: {
      ports: { some: { name: { contains: $originPortName} } }
      and: { ports: { some: { name: { contains: $destinationPortName} } } }
    }
  ) {
    routeCode
    name
    routeInformation
    polyline
    location {
      name
    }
    ports {
      name
      portCode
      order
      latitude
      longitude
    }
  }
}
`;

const getAllRoutesMapCoordsQuery = gql`
query {
  routes {
    name
    routeCode
    polyline
    ports{
      portCode
      longitude
      latitude
      name
      portImage
    }
    location {
      name
      latitude
      longitude
      locationCode
      locationImage
    }
    originPort{
      name
      portCode
    }
    destinationPort{
      name
      portCode
    }
  }
}
`;

const getRoutesServedByRouteCodeQuery = gql`
query 
(
  $routeCode: String
) {
  routes (where: {routeCode: {eq: $routeCode}})
  {
    description
    routeCode
    name
    keyInformation
    polyline
    vessels{
      name
      vesselUrl
      vesselCode
    }
    ports{
      name
      portCode
      portUrl
      longitude
      latitude
      location{
        name
      }
    }
  }
}
`;

const getRouteMapByRouteCode = gql`
query 
(
  $routeCode: String
) {
  routes (where: {routeCode: {eq: $routeCode}})
  {
    name
    routeCode
    polyline
    ports{
      name
      portCode
      longitude
      latitude
    }
  }
}
`;

const getAllRoutesQuery = gql`
query 
{
  routes 
  {
    id
    routeCode
    textCode
    name
    routeInformation
    polyline
    location {
      name
    }
    ports {
      name
      portCode
      order
      latitude
      longitude
    }
  }
}
`;

export {
  getRoutesWithStatusQuery,
  getRouteInformationQuery,
  getRouteInformationByPortsQuery,
  getAllRoutesMapCoordsQuery,
  getRoutesServedByRouteCodeQuery,
  getRouteMapByRouteCode,
  getAllRoutesQuery,
};
