import {
  watch,
  Ref,
  ref,
  computed,
} from 'vue';
import { RedirectRequest } from '@azure/msal-browser';
import { useMsal } from './useMsal';
import { useMsalToken } from './useMsalToken';

export const useIsAuthenticated = (redirectRequest: RedirectRequest) => {
  const { activeAccount } = useMsal();

  const isAuthenticated: Ref<boolean | undefined> = ref(undefined);

  const { acquireToken, result } = useMsalToken(redirectRequest);

  const hasAccount = computed(() => !!activeAccount.value);

  watch(() => hasAccount.value, (newValue) => {
    if (newValue) {
      acquireToken(redirectRequest, false).then(() => {
        isAuthenticated.value = !!activeAccount.value && !!result.value;
      });
      return;
    }
    isAuthenticated.value = false;
  }, { immediate: true });

  return {
    isAuthenticated,
  };
};
