import { Route } from '../apiTypes';
import { RecentOrSuggestedSearchEntry } from '..';

export const mapRouteToRecentOrSuggestedSearchEntry = (item:Route) => ({
  iconName: 'mapPin',
  ports: item.ports.map((port) => ({
    name: port.name,
    portCode: `(${port.portCode})`,
    order: port.order,
  })).sort((a, b) => a.order - b.order),
} as RecentOrSuggestedSearchEntry);
