import { defineStore } from 'pinia';

export const useSharedState = defineStore('sharedState', {
  state: () => ({
    shared: {} as any,
  }),
  actions: {
    // Check localstorage for a persisted state and pull into shared state
    getState(stateName) {
      const stateToAdd: string | null = window.localStorage.getItem(stateName);
      if (stateToAdd) {
        this.updateSharedState(stateName, JSON.parse(stateToAdd));
      }
    },
    // Update a value
    updateSharedState(key, value) {
      this.shared[key] = value;
    },
    // Remove from shared state
    removeSharedState(key) {
      this.shared[key] = undefined;
    },
  },
  getters: {
    getSharedState: (state) => {
      const allState = this as any;
      const upToDateState = Object.keys(state.shared).forEach((key) => {
        allState.getState(key);
      });
      return upToDateState;
    },
  },
  persist: true,
});
