import gql from 'graphql-tag';

const getTimetablesSearchQuery = gql`
query (
  $originPortName: String
  $destinationPortName: String
  $sailingStartDate: Date
  $sailingEndDate: Date
) {
  portPairSailings (
    where: {
      and: [
        { originPort: { name: { eq: $originPortName } } }
        { destinationPort: { name: { eq: $destinationPortName } } }
        { sailingDate: { gte: $sailingStartDate } }
        { sailingDate: { lt: $sailingEndDate } }
      ]
    }
    order: { departureDateTime: ASC }
  ) {
    sailingDate
    route {
      id
      routeCode
      originPort {
        name
      }

      destinationPort {
        name
      }
    }
    departureDateTime
    arrivalDateTime
    rescheduledDepartureDateTime
    rescheduledArrivalDateTime
    sailingLegs (order: { order: ASC}) {
      originPort {
        name
      }
      destinationPort {
        name
      }
      departureDateTime
      arrivalDateTime
      rescheduledDepartureDateTime
      rescheduledArrivalDateTime
      order
    }
    status
  }
}
`;

const getPortPairsSuggestionsByOriginPort = gql`
query ($portOrLocationName: String) {
  portPairs(
    where: {
      or: [
        { originLocation: { contains: $portOrLocationName } }
        { originPort: { contains: $portOrLocationName } }
      ]
    }
  ) {
    originLocation
    originPort
    originPortCode
    destinationLocation
    destinationPort
    destinationPortCode
    route {
      id
      routeCode
    }
  }
}
`;

const getPortPairsSuggestionsByDestinationPort = gql`
query ($portOrLocationName: String) {
  portPairs(
    where: {
      or: [
        { destinationLocation: { contains: $portOrLocationName } }
        { destinationPort: { contains: $portOrLocationName } }
      ]
    }
  ) {
    originLocation
    originPort
    originPortCode
    destinationLocation
    destinationPort
    destinationPortCode
    route {
      id
      routeCode
    }
  }
}
`;

const getRouteByOriginDestinationPortPairs = gql`
query (
  $originPortName: String
  $destinationPortName: String
  ) {
  portPairs(
    where: {
      and: [
        { originPort: { contains: $originPortName } }
        { destinationPort: { contains: $destinationPortName } }
      ]
    }
  ) {
    route {
      id
      routeCode
    }
  }
}
`;

export {
  getTimetablesSearchQuery,
  getPortPairsSuggestionsByOriginPort,
  getPortPairsSuggestionsByDestinationPort,
  getRouteByOriginDestinationPortPairs,
};
