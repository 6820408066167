import { RouteStatusCategory } from '../types/api';

export const mapRouteStatusCategoryToColour = (status: RouteStatusCategory) => {
  switch (status) {
    case RouteStatusCategory.AllSailingsCancelled:
      return '#E40520';
    case RouteStatusCategory.Disruptions:
      return '#F2934E';
    case RouteStatusCategory.BeAware:
      return '#FFD964';
    default:
      return '#37BF35';
  }
};
