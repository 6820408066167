import { B2CConfig } from '@auth/types';
import { createInstance } from '@auth/create';

import {
  b2cClientId, b2cLoginPolicy, b2cPostLogoutRedirectUri, b2cRedirectUri, b2cTenant, b2cHostname,
} from '@/envVariables';

const authConfig: B2CConfig = {
  clientId: b2cClientId,
  tenant: b2cTenant,
  hostname: b2cHostname,
  signInPolicy: b2cLoginPolicy,
  redirectUri: b2cRedirectUri,
  postLogoutRedirectUri: b2cPostLogoutRedirectUri,
};
export const msalInstance = createInstance(authConfig);
