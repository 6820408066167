import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import {
  getRoutesQuery,
  getRouteServiceStatusQuery,
  getRouteSuggestionQuery,
  getServiceStatusQuery,
} from './queries';
import {
  Routes,
  Route,
  RouteStatusCategory,
} from '../types/api';
import { apolloClient } from '../services/ApolloClient';

provideApolloClient(apolloClient);

const serviceStatus = ref<Route[]>();
const queryResults = ref<Routes>();
const routeSuggestion = ref<Routes>();
const routeServiceStatus = ref<Route>();
const selectedRoute = ref<Route>();
// const allRoutes = ref<Routes>();
const affectedRoutes = ref<Route[]>();
const recentSearches = ref<Array<string>>([]);

const serviceStatusErrorMessage = ref<string | undefined>();
const searchRouteStatusesErrorMessage = ref<string | undefined>();
const routesSuggestionErrorMessage = ref<string | undefined>();
const routeServiceStatusErrorMessage = ref<string | undefined>();
const isServiceStatusError = ref<boolean>();
const isSearchRouteStatusesError = ref<boolean>();
const isRoutesSuggestionError = ref<boolean>();
const isRouteServiceStatusError = ref<boolean>();

// Where race condition occur isLaoding may be false when it should be true
const isLoading = ref<boolean>(false);

const processResult = ((queryResult, document) => {
  if (!queryResult.data || queryResult.loading) {
    return;
  }

  if (document.value.loc?.source.body === getServiceStatusQuery.loc?.source.body) {
    serviceStatus.value = queryResult.data.routes;
  }
  if (document.value.loc?.source.body === getRouteServiceStatusQuery.loc?.source.body) {
    routeServiceStatus.value = queryResult.data.routes?.[0];
  }
  if (document.value.loc?.source.body === getRoutesQuery.loc?.source.body) {
    queryResults.value = queryResult.data;
  }
  if (document.value.loc?.source.body === getRouteSuggestionQuery.loc?.source.body) {
    routeSuggestion.value = queryResult.data;
  }
});

export const serviceStatusStoreDefinition = {
  state: () => ({
    serviceStatus,
    routeServiceStatus,
    isLoading,
    queryResults,
    routeSuggestion,
    selectedRoute,
    affectedRoutes,
    recentSearches,
    serviceStatusErrorMessage,
    searchRouteStatusesErrorMessage,
    routesSuggestionErrorMessage,
    routeServiceStatusErrorMessage,
    isServiceStatusError,
    isSearchRouteStatusesError,
    isRoutesSuggestionError,
    isRouteServiceStatusError,
  }),
  actions: {
    async getServiceStatus() {
      const {
        refetch,
        onResult,
        onError,
        loading,
        document,
      } = useQuery(getServiceStatusQuery);
      isLoading.value = loading.value;
      isServiceStatusError.value = false;
      serviceStatusErrorMessage.value = undefined;

      onError((error) => {
        isLoading.value = loading.value;
        isServiceStatusError.value = true;
        serviceStatusErrorMessage.value = error.message;
        console.log('getServiceStatus: error', error);
      });

      onResult((queryResult) => {
        isLoading.value = loading.value;
        processResult(queryResult, document);
      });

      await refetch();
    },
    async getSearchRouteStatuses(q: string) {
      const {
        refetch,
        onResult,
        onError,
        loading,
        document,
      } = useQuery(getRoutesQuery, { routeName: q });
      isLoading.value = loading.value;
      isSearchRouteStatusesError.value = false;
      searchRouteStatusesErrorMessage.value = undefined;

      onError((error) => {
        isLoading.value = loading.value;
        isSearchRouteStatusesError.value = true;
        searchRouteStatusesErrorMessage.value = error.message;
        console.log('getSearchRouteStatuses: error', error);
      });

      onResult((queryResult) => {
        isLoading.value = loading.value;
        processResult(queryResult, document);
      });

      await refetch();
    },
    getRoute(routeCode) {
      return serviceStatus.value?.filter((item) => item.routeCode === routeCode)?.[0];
    },
    clearSearchRouteStatuses() {
      queryResults.value = undefined;
    },
    async getRoutesSuggestion(q: string) {
      const {
        refetch,
        onResult,
        onError,
        document,
      } = useQuery(getRouteSuggestionQuery, { routeName: q });
      isRoutesSuggestionError.value = false;
      routesSuggestionErrorMessage.value = undefined;

      onError((error) => {
        isRoutesSuggestionError.value = true;
        routesSuggestionErrorMessage.value = error.message;
        console.log('getRoutesSuggestion: error', error);
      });

      onResult((queryResult) => {
        processResult(queryResult, document);
      });

      await refetch();
    },
    async getRouteServiceStatus(routeCode: string) {
      const {
        refetch,
        onResult,
        onError,
        loading,
        document,
      } = useQuery(getRouteServiceStatusQuery, { routeCode });
      isLoading.value = loading.value;
      isRouteServiceStatusError.value = false;
      routeServiceStatusErrorMessage.value = undefined;

      onError((error) => {
        isLoading.value = loading.value;
        isRouteServiceStatusError.value = true;
        routeServiceStatusErrorMessage.value = error.message;
        console.log('getRouteServiceStatus: error', error);
      });

      onResult((queryResult) => {
        isLoading.value = loading.value;
        processResult(queryResult, document);
      });

      await refetch();
    },
    updateSelectedRoute: (route: Route) => {
      selectedRoute.value = route;
    },
    addRecentSearch(q: string, isMobile = false) {
      if (!q) {
        return;
      }
      // remove item from list if it already exists
      recentSearches.value = recentSearches.value.filter(
        (searchItem) => q.toLowerCase() !== searchItem.toLowerCase(),
      );
      // Add item to the top of the list
      recentSearches.value?.unshift(q);

      const maxNumberOfRecent = isMobile ? 3 : 5;

      if (recentSearches.value.length > maxNumberOfRecent) {
        recentSearches.value.pop();
      }
    },
    clearRecentSearches() {
      recentSearches.value = [];
    },
  },
  getters: {
    getSelectedRoute: () => selectedRoute.value,
    getAffectedRoutes: () => serviceStatus
      .value?.filter((item) => item.status !== RouteStatusCategory.Normal) || [],
    getApiErrorStatus: () => (
      isServiceStatusError.value
      || isSearchRouteStatusesError.value
      || isRoutesSuggestionError.value
      || isRouteServiceStatusError.value
    ),
  },
  persist: {
    storage: localStorage,
    paths: ['recentSearches'],
  },
};

export const useServiceStatusStore = defineStore('serviceStatusStore', serviceStatusStoreDefinition);
