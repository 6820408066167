import { Ref, ref } from 'vue';
import {
  AuthenticationResult,
  AuthError,
  InteractionStatus,
  PopupRequest,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';

import { useMsal } from './useMsal';

export type MsalAuthenticationResult = {
    acquireToken: (
      requestOverride?: PopupRequest|RedirectRequest|SilentRequest,
      redirectOnError?: boolean)
      => Promise<string | undefined>;
    result: Ref<AuthenticationResult|null>;
    error: Ref<AuthError|null>;
    inProgress: Ref<boolean>;
}

export const useMsalToken = (
  request: RedirectRequest|SilentRequest,
): MsalAuthenticationResult => {
  const { instance, inProgress } = useMsal();

  const localInProgress = ref<boolean>(false);
  const result = ref<AuthenticationResult|null>(null);
  const error = ref<AuthError|null>(null);

  const acquireToken = async (
    requestOverride?: RedirectRequest | SilentRequest,
    redirectOnError = true,
  ): Promise<string | undefined> => {
    if (localInProgress.value) {
      return undefined;
    }
    localInProgress.value = true;
    const tokenRequest = requestOverride || request;
    try {
      const response = await instance.acquireTokenSilent(tokenRequest);
      result.value = response;
      const { accessToken } = response;
      localInProgress.value = false;
      error.value = null;
      return accessToken;
    } catch (e) {
      console.log('b2c: an error occurred while acquiring token silently');
      if (inProgress.value !== InteractionStatus.None) {
        return undefined;
      }
      console.log('b2c: login using redirect after silent token fetch fail');
      if (!redirectOnError) {
        return undefined;
      }
      await instance.loginRedirect(tokenRequest).catch((innerError) => {
        error.value = innerError;
        result.value = null;
      });
      localInProgress.value = false;
    }
    return undefined;
  };

  return {
    acquireToken,
    result,
    error,
    inProgress: localInProgress,
  };
};
