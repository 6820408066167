import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import {
  getTimetablesSearchQuery,
  getPortPairsSuggestionsByOriginPort,
  getPortPairsSuggestionsByDestinationPort,
  getRouteByOriginDestinationPortPairs,
} from './queries/timetables';
import { apolloClient } from '../services/ApolloClient';
import {
  PortPair,
  Route,
  TimetableSearch,
  PortPairSailing,
} from '../types/api';
import { mapPortPairSailings } from '../maps/mapSailings';
import { endOfDay, startOfDay } from '..';

provideApolloClient(apolloClient);

const recentSearches = ref<Array<TimetableSearch>>();

const routeSuggestions = ref<Route[]>();
const portPairSuggestions = ref<PortPair[]>();
const timetableSearchResults = ref<PortPairSailing[]>();
const portPairRouteId = ref<string>();
const portPairRouteCode = ref<string>();

const isLoading = ref<boolean | undefined>(undefined);
const isServerError = ref<boolean>(false);

const processResult = ((queryResult, document) => {
  if (!queryResult.data || queryResult.loading) {
    return;
  }

  if (document.value.loc?.source.body === getTimetablesSearchQuery.loc?.source.body) {
    timetableSearchResults.value = queryResult.data?.portPairSailings;
  }
  if (document.value.loc?.source.body === getPortPairsSuggestionsByOriginPort.loc?.source.body) {
    portPairSuggestions.value = queryResult.data?.portPairs;
  }
  if (document.value.loc?.source.body
    === getPortPairsSuggestionsByDestinationPort.loc?.source.body) {
    portPairSuggestions.value = queryResult.data?.portPairs;
  }
  if (document.value.loc?.source.body
    === getRouteByOriginDestinationPortPairs.loc?.source.body) {
    portPairRouteId.value = queryResult.data?.portPairs?.[0]?.route?.id;
    portPairRouteCode.value = queryResult.data?.portPairs?.[0]?.route?.routeCode;
  }
});

export const timetableStoreDefinition = {
  state: () => ({
    recentSearches,
    timetableSearchResults,
    routeSuggestions,
    portPairSuggestions,
    portPairRouteId,
    portPairRouteCode,
    isLoading,
    isServerError,
  }),
  actions: {
    getRecentSearches() { return recentSearches.value; },
    async getSearchResults(
      newSearch: TimetableSearch,
      shouldUpdateRecentSearches = true,
      isMobile = false,
    ) {
      const {
        refetch,
        onResult,
        onError,
        loading,
        document,
      } = useQuery(
        getTimetablesSearchQuery,
        {
          originPortName: newSearch.from,
          destinationPortName: newSearch.to,
          sailingStartDate: startOfDay(newSearch.fromDate),
          sailingEndDate: newSearch.toDate
            ? endOfDay(newSearch.toDate)
            : endOfDay(newSearch.fromDate),
        },
      );

      isServerError.value = false;
      isLoading.value = loading.value;

      onError((error) => {
        isLoading.value = loading.value;
        isServerError.value = true;
        timetableSearchResults.value = [];
      });

      onResult((queryResult) => {
        isLoading.value = loading.value;
        processResult(queryResult, document);
      });

      if (!loading.value) {
        await refetch();
      }

      if (shouldUpdateRecentSearches) {
        this.addSearchToRecent(newSearch, isMobile);
      }
      portPairRouteId.value = undefined;
      portPairRouteCode.value = undefined;
    },
    addSearchToRecent(newSearch: TimetableSearch, isMobile = false) {
      // all these fields must be present to save recent search
      if (!newSearch.from || !newSearch.to || !newSearch.routeCode) {
        return;
      }

      const newRecentSearch: TimetableSearch = {
        from: decodeURI(newSearch.from),
        to: decodeURI(newSearch.to),
        fromDate: newSearch.fromDate,
        routeCode: newSearch.routeCode,
      };

      if (recentSearches.value === undefined) {
        recentSearches.value = [newRecentSearch];
        return;
      }

      // remove item from list if it already exists
      recentSearches.value = recentSearches.value.filter(
        (searchItem: TimetableSearch) => searchItem.from !== newRecentSearch.from
        || searchItem.to !== newRecentSearch.to,
      );
      // Add item to the top of the list
      recentSearches.value?.unshift(newRecentSearch);

      const maxNumberOfRecent = isMobile ? 3 : 5;

      if (recentSearches.value.length > maxNumberOfRecent) {
        recentSearches.value.pop();
      }
    },
    async getSuggestionsByOriginPortName(originPortName: string) {
      const {
        refetch,
        onResult,
        onError,
        loading,
        document,
      } = useQuery(
        getPortPairsSuggestionsByOriginPort,
        { portOrLocationName: originPortName },
      );
      isLoading.value = loading.value;
      isServerError.value = false;

      onError((error) => {
        isLoading.value = loading.value;
        isServerError.value = true;
        console.log('getSuggestionsByOriginPortName: error', error);
      });

      onResult((queryResult) => {
        isLoading.value = loading.value;
        processResult(queryResult, document);
      });

      portPairSuggestions.value = undefined;
      await refetch();
    },
    async getSuggestionsByDestinationPortName(destinationPortName: string) {
      const {
        refetch,
        onResult,
        onError,
        loading,
        document,
      } = useQuery(
        getPortPairsSuggestionsByDestinationPort,
        { portOrLocationName: destinationPortName },
      );

      isLoading.value = loading.value;
      isServerError.value = false;

      onError((error) => {
        isLoading.value = loading.value;
        isServerError.value = true;
        console.log('getSuggestionsByDestinationPortName: error', error);
      });

      onResult((queryResult) => {
        isLoading.value = loading.value;
        timetableSearchResults.value = [];
        processResult(queryResult, document);
      });

      await refetch();
    },
    async getRouteByOriginDestinationPortName(originPortName: string, destinationPortName: string) {
      portPairRouteId.value = undefined;
      portPairRouteCode.value = undefined;
      const {
        refetch,
        onResult,
        onError,
        loading,
        document,
      } = useQuery(
        getRouteByOriginDestinationPortPairs,
        {
          originPortName,
          destinationPortName,
        },
      );

      isLoading.value = loading.value;
      onError((error) => {
        isLoading.value = loading.value;
        isServerError.value = true;
        console.log('getRouteByOriginDestinationPortName: error', error);
      });

      onResult((queryResult) => {
        isLoading.value = loading.value;
        processResult(queryResult, document);
      });

      await refetch();
    },
    clearSuggestions() {
      portPairSuggestions.value = undefined;
    },
    clearError() {
      isServerError.value = false;
    },
  },
  getters: {
    getSailingResults: () => timetableSearchResults.value?.slice().map(mapPortPairSailings),
  },
  persist: {
    storage: localStorage,
    paths: ['recentSearches'],
  },
};

export const useTimetableStore = defineStore('timetableStore', timetableStoreDefinition);
