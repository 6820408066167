import { Port } from '..';
import { Port as ApiPort } from '../types/api';

export const mapPort = (port: ApiPort) => ({
  name: port.name,
  portCode: `(${port.portCode})`,
  order: port.order,
  latitude: port.latitude,
  longitude: port.longitude,
}) as Port;
