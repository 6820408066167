import { RouteServiceStatus } from '../types/RouteServiceStatus';
import {
  Route,
} from '../types/api/index';
import { mapPort, parseRouteStatusByStatusCategory, sortPorts } from '.';

export const mapRoutes = (item: Route) => ({
  id: item.id,
  routeCode: item.routeCode,
  destinationName: item.location?.name,
  ports: item.ports.map(mapPort).sort(sortPorts),
  status: parseRouteStatusByStatusCategory(item.status),
} as RouteServiceStatus);
