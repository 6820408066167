import {
  parseISO,
} from 'date-fns';

const utcDateTime = (date: Date) => new Date(Date.UTC(
  date.getUTCFullYear(),
  date.getUTCMonth(),
  date.getUTCDate(),
  date.getUTCHours(),
  date.getUTCMinutes(),
  date.getUTCSeconds(),
  date.getUTCMilliseconds(),
));

const utcDate = (date: Date | string) => new Date(Date.UTC(
  typeof date === 'string' ? parseISO(date).getUTCFullYear() : date.getUTCFullYear(),
  typeof date === 'string' ? parseISO(date).getUTCMonth() : date.getUTCMonth(),
  typeof date === 'string' ? parseISO(date).getUTCDate() : date.getUTCDate(),
));

function addDays(date: string, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result.toISOString();
}

// Difference in days between two dates in ISO format YYYY-MM-DD
const differenceInCalendarDays = (date1: string, date2: string) => {
  const date1Date = parseISO(date1);
  const date2Date = parseISO(date2);
  return Math.round((date2Date.getTime() - date1Date.getTime()) / (1000 * 3600 * 24));
};

// return end of day date time from date string
const endOfDay = (date: string) => utcDate(addDays(date, 1)).toISOString();

const previousDay = (date: string) => utcDate(addDays(date, -1)).toISOString();

const startOfDay = (date: string) => utcDate(date).toISOString();

const utcNow = (): Date => utcDateTime(new Date());

const nowOrStartOfDay = (date: string): string => {
  const now = utcNow();
  const dateInput = parseISO(date);
  if (dateInput.getUTCDate() === now.getUTCDate()
    && dateInput.getUTCMonth() === now.getUTCMonth()
    && dateInput.getUTCFullYear() === now.getUTCFullYear()) {
    return date;
  }
  return startOfDay(date);
};

const dateIsLessThanCurrentTime = (dateInput: Date, currentDate = new Date()): boolean =>
  dateInput.getTime() < currentDate.getTime();

export {
  utcDateTime,
  utcDate,
  endOfDay,
  startOfDay,
  differenceInCalendarDays,
  nowOrStartOfDay,
  utcNow,
  previousDay,
  dateIsLessThanCurrentTime,
  addDays,
};
