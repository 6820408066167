import ServiceStatus from './ServiceStatus.vue';
import ServiceStatusSearchBox from './ServiceStatusSearchbox.vue';
import { ServiceStatusType } from '../../types/ServiceStatusType';

const GetServiceStatus = function (serviceStatus?: ServiceStatusType) {
  switch (serviceStatus) {
    case ServiceStatusType.AtRisk:
      return { name: 'statusAtRisk', text: 'Possible disruptions' };
    case ServiceStatusType.Disruption:
      return { name: 'statusDelayed', text: 'Disruptions' };
    case ServiceStatusType.Cancelled:
      return { name: 'statusCancelled', text: 'Cancelled for the remainder of today' };
    default:
      return { name: 'statusNormal', text: 'Service normal' };
  }
};

export {
  ServiceStatus, ServiceStatusType, GetServiceStatus, ServiceStatusSearchBox,
};
