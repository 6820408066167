import { PortPair } from '../apiTypes';
import { RecentOrSuggestedSearchEntry } from '../types';

export const mapPortPairToRecentOrSuggestedSearchEntry = (suggestion: PortPair) => ({
  iconName: 'mapPin',
  ports: [
    {
      name: suggestion.originPort,
      portCode: suggestion.originPortCode,
    },
    {
      name: suggestion.destinationPort,
      portCode: suggestion.destinationPortCode,
    },
  ],
  location: suggestion.originLocation,
  routeId: suggestion?.route?.id,
  routeCode: suggestion?.route?.routeCode,
} as RecentOrSuggestedSearchEntry);
