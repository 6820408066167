<template>
  <div>
    <p>Dummy View</p>
  </div>
</template>

<script setup lang='ts'>
</script>

<style lang="scss" scoped>
</style>
