import { AccountInfo, InteractionStatus, PublicClientApplication } from '@azure/msal-browser';
import { getCurrentInstance, Ref, toRefs } from 'vue';
import { MsalState } from '../types/msalState';

export type MsalContext = {
    instance: PublicClientApplication,
    accounts: Ref<AccountInfo[]>,
    inProgress: Ref<InteractionStatus>
    activeAccount: Ref<AccountInfo | null>
}

export const useMsal = (): MsalContext => {
  const internalInstance = getCurrentInstance();
  if (!internalInstance) {
    throw new Error('useMsal() cannot be called outside the setup() function of a component');
  }
  const {
    instance,
    accounts,
    inProgress,
    activeAccount,
  } = toRefs(internalInstance.appContext.config.globalProperties.$msal as MsalState);

  if (!instance || !accounts || !inProgress) {
    throw new Error('Please install the msalPlugin');
  }

  return {
    instance: instance.value,
    accounts,
    inProgress,
    activeAccount,
  };
};
