import gql from 'graphql-tag';

const getRouteSubscriptions = gql`
query {
  routeSubscriptions
  {
    id
    mobileNumber
    textCode
    channel
    startDate
    endDate
  }
}
`;

const upsertRouteSubscription = gql`
mutation UpsertRouteSubscription($upsertRouteSubscriptionInput: UpsertRouteSubscriptionInput!) {
  upsertRouteSubscription(upsertRouteSubscriptionInput: $upsertRouteSubscriptionInput) {
    routeSubscription {
      id
      textCode
      channel
      startDate
      endDate
  }
    errors {
      ... on IBaseError {
        message
      }
    }
  }
}
`;

const deleteRouteSubscription = gql`
mutation DeleteRouteSubscription($deleteRouteSubscriptionInput: DeleteRouteSubscriptionInput!) {
  deleteRouteSubscription(deleteRouteSubscriptionInput: $deleteRouteSubscriptionInput) {
    removedId
    errors {
      ... on IBaseError {
        message
      }
    }
  }
}
`;

export {
  getRouteSubscriptions,
  upsertRouteSubscription,
  deleteRouteSubscription,
};
