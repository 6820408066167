import { mapRouteInformationToRouteDetails } from '../../maps/mapRouteInformationToRouteDetails';
import { RouteDetail } from '../../types';
import { RouteInformation, RouteSubscription } from '../../types/api';

export const determineAvailableRoutes = ({ allRoutes = [], subscriptions = [] } : {
  allRoutes: RouteInformation[],
  subscriptions: RouteSubscription[],
}): RouteDetail[] => {
  if (!allRoutes) {
    return [];
  }

  if (!subscriptions) {
    return allRoutes.map(mapRouteInformationToRouteDetails);
  }

  return allRoutes.filter((route: RouteInformation) =>
    !subscriptions.find(
      (subscription: RouteSubscription) => subscription.textCode === route.textCode,
    ))
    .map(mapRouteInformationToRouteDetails);
};
