import { RouteDetail } from '@components/renderTypes';

export const filterRoutesBySearchTerm = ({
  routes = [],
  searchTerm = '',
}: {
  routes: RouteDetail[],
  searchTerm: string
}): RouteDetail[] => {
  if (!searchTerm) return routes;

  if (!routes) return [];

  const res = routes.filter(
    (route: RouteDetail) =>
      route.ports.some(({ name, portCode }) =>
        name.toLowerCase().includes(searchTerm.toLowerCase())
        || (portCode && portCode.toLowerCase().includes(searchTerm.toLowerCase()))),
  );

  return res;
};
