export const orderByTextCodeAsc = (
  a: { textCode: string},
  b: { textCode: string},
) => {
  if (Number(a.textCode) < Number(b.textCode)) {
    return -1;
  }
  if (Number(a.textCode) > Number(b.textCode)) {
    return 1;
  }
  return 0;
};
