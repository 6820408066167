import { DictionaryText } from './types';

export const defaultValueText: DictionaryText = {
  'SMS:Web:AddNewRoute': 'Add new route',
  'SMS:Web:NoRoutes:Title': 'You do not have any routes yet',
  'SMS:Web:NoRoutes:Intro': 'Stay up to date on latest route information. Start adding new route(s) and get SMS notifications to your phone.',
  'SMS:Web:HowItWorks:Title': 'How it works',
  'SMS:Web:HowItWorks:Description': `Choose the route from the list provided and enter the date range of the subscription. Text message for the subscribed routes will be dispatched to you immediately upon update, at any time of day or night.

Your subscription can be for any period between 1 day and 6 months. This can begin up to 6 months in the future. The choice is yours.

Once you're happy with your route and date choices remember to save/update your changes.`,
  'SMS:Web:HowItWorks:Alert:Title': 'Please note',
  'SMS:Web:HowItWorks:Alert:Description': 'Date range is the length of time (days, weeks, months) your subscription will be active.',
};
