import { Sailing, Stop, PortPairSailing } from '../types';
import { Sailing as ISailing, Leg, PortPairSailing as IPortPairSailing } from '../types/api';
import { mapSailingStatusToTimeTableStatusType } from './mapSailingStatusToTimeTableStatusType';

export const mapLegs = (item:Leg) => ({
  time: item.departureDateTime
    ? new Date(Date.parse(item.departureDateTime))
    : undefined,
  port: item.originPort.name,
  order: item.order,
} as Stop);

export const mapSailings = (item: ISailing) => ({
  status: mapSailingStatusToTimeTableStatusType(item.status),
  departureTime: new Date(Date.parse(item.departureDateTime)),
  arrivalTime: new Date(Date.parse(item.arrivalDateTime)),
  delayedDepartureTime: item.rescheduledDepartureDateTime
    ? new Date(Date.parse(item.rescheduledDepartureDateTime))
    : undefined,
  delayedArrivalTime: item.rescheduledArrivalDateTime
    ? new Date(Date.parse(item.rescheduledArrivalDateTime))
    : undefined,
  isDelayed: item.status.toUpperCase() === 'DELAYED',
  isCancelled: item.status.toUpperCase() === 'CANCELLED',
  stops: item.legs.map(mapLegs),
} as Sailing);

export const mapPortPairSailings = (item: IPortPairSailing) => {
  const stops: Stop[] = item.sailingLegs.map(mapLegs);
  stops.push(mapLegs({
    departureDateTime: item.sailingLegs[item.sailingLegs.length - 1].arrivalDateTime
      ? item.sailingLegs[item.sailingLegs.length - 1].arrivalDateTime
      : undefined,
    originPort: item.sailingLegs[item.sailingLegs.length - 1].destinationPort,
    destinationPort: item.sailingLegs[item.sailingLegs.length - 1].destinationPort,
    order: item.sailingLegs.length,
  } as Leg));

  const portPairSailing = {
    status: mapSailingStatusToTimeTableStatusType(item.status),
    departureTime: new Date(Date.parse(item.departureDateTime)),
    arrivalTime: new Date(Date.parse(item.arrivalDateTime)),
    delayedDepartureTime: item.rescheduledDepartureDateTime
      ? new Date(Date.parse(item.rescheduledDepartureDateTime))
      : undefined,
    delayedArrivalTime: item.rescheduledArrivalDateTime
      ? new Date(Date.parse(item.rescheduledArrivalDateTime))
      : undefined,
    isDelayed: item.status.toUpperCase() === 'DELAYED',
    isCancelled: item.status.toUpperCase() === 'CANCELLED',
    stops,
  } as PortPairSailing;

  return portPairSailing;
};
