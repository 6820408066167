import { RouteSubscriptionViewModel, SmsErrors } from '../../types';
import { isValidSubscriptionRange } from './determineSubscriptionDates';

export const validateSubscription = (subscription: RouteSubscriptionViewModel) : SmsErrors => {
  const errors: SmsErrors = {
    textCode: false,
    rangeError: false,
  };

  if (!subscription.textCode) {
    errors.textCode = true;
  }

  if (!isValidSubscriptionRange(subscription.startDate, subscription.endDate)) {
    errors.rangeError = true;
  }
  return errors;
};
