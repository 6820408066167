import { RouteDetail } from '../types';
import { RouteSubscriptionUpdateModel } from '..';
import { UpsertRouteSubsciptionApi } from '../types/api';
import { addDays } from '../helpers/dateTimeUtilities';
import { Channel } from '../types/api/channel';

export const mapUpdateRouteSubscriptionToApi = (
  { routeSubscription, allRoutes = [] }:
  { routeSubscription: RouteSubscriptionUpdateModel; allRoutes: RouteDetail[] },
)
: UpsertRouteSubsciptionApi | undefined => {
  if (!allRoutes || allRoutes.length === 0) {
    return undefined;
  }

  if (!routeSubscription) {
    return undefined;
  }

  const textCode = allRoutes
    .find((route: RouteDetail) => route.textCode === routeSubscription.textCode)?.textCode || '';
  return {
    textCode,
    channel: Channel.Web,
    startDate: routeSubscription.startDate,
    endDate: addDays(routeSubscription.endDate, 1),
  };
};
