import { filterOutExpiredSubscriptionsFromApi, orderByTextCodeAsc } from '../helpers';
import { RouteInformation, RouteSubscription } from '../types/api';
import { mapRouteSubscriptionToSaveModel } from './mapRouteSubscriptionToSaveModel';
import { RouteSubscriptionSavedModel } from '..';

export const mapRouteSubscriptionsToSavedView = ({
  allRoutes = [],
  subscriptions = [],
}:
{
  allRoutes: RouteInformation[];
  subscriptions: RouteSubscription[];
}) : RouteSubscriptionSavedModel[] | undefined => {
  if (!subscriptions) {
    return undefined;
  }
  return (
    (filterOutExpiredSubscriptionsFromApi(subscriptions)
      .map(
        (item: RouteSubscription) => mapRouteSubscriptionToSaveModel({
          item,
          allRoutes: allRoutes || [],
        }),
      )
      .filter((item: RouteSubscriptionSavedModel | undefined) =>
        item !== undefined) as RouteSubscriptionSavedModel[])
      .sort(orderByTextCodeAsc));
};
