import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getPortsServedByPortCodeQuery } from './queries/port';
import { apolloClient } from '../services/ApolloClient';
import { Port } from '../apiTypes';
import { mapRoutesToMapRoutes, mapRouteToRouteServed } from '../maps';
import { RoutesServedType } from '../types/RenderTypes';

provideApolloClient(apolloClient);

const portsServed = ref<Port>();
const isLoading = ref<boolean>(false);

const processResult = ((queryResult, document) => {
  if (document.value.loc?.source.body === getPortsServedByPortCodeQuery.loc?.source.body) {
    portsServed.value = queryResult.data?.ports[0];
  }
});

export const portStoreDefinition = {
  state: () => ({
    portsServed,
    isLoading,
  }),
  actions: {
    async requestPortsServed(portCode: string) {
      const {
        onResult,
        onError,
        loading,
        document,
      } = useQuery(getPortsServedByPortCodeQuery, {
        portCode,
      });
      isLoading.value = loading.value;

      onError((error) => {
        isLoading.value = loading.value;
        console.log('requestPortsServed: error', error);
      });

      onResult((queryResult) => {
        isLoading.value = loading.value;
        processResult(queryResult, document);
      });
    },
  },
  getters: {
    getPortsServed() {
      return portsServed.value?.routes
        ? portsServed.value.routes.map((route) => ({
          ...mapRouteToRouteServed(route),
          routeMap: [mapRoutesToMapRoutes(route)],
        } as RoutesServedType))
        : undefined;
    },
  },
};

export const usePortStore = defineStore('portStore', portStoreDefinition);
