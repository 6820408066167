import { RouteStatusCategory } from '../apiTypes';
import { ServiceStatusType } from '..';

export const parseRouteStatusByStatusCategory = (routeStatus: RouteStatusCategory) => {
  switch (routeStatus) {
    case RouteStatusCategory.AllSailingsCancelled.valueOf():
      return ServiceStatusType.Cancelled;
    case RouteStatusCategory.Disruptions.valueOf():
      return ServiceStatusType.Disruption;
    case RouteStatusCategory.BeAware.valueOf():
      return ServiceStatusType.AtRisk;
    default:
      return ServiceStatusType.Normal;
  }
};
