import {
  ref, computed, Ref, watch,
} from 'vue';
import {
  provideApolloClient, useMutation, useQuery,
} from '@vue/apollo-composable';
import { defineStore, storeToRefs } from 'pinia';

import { ApolloQueryResult } from '@apollo/client';
import {
  GetRouteSubscriptionsResponse,
  RouteSubscription,
} from '../types/api';
import { apolloClient } from '../services/ApolloClient';
import { useRoutesStore } from './useRoutesStore';
import { deleteRouteSubscription, getRouteSubscriptions, upsertRouteSubscription } from './queries';
import {
  RouteDetail,
  RouteSubscriptionSavedModel,
  RouteSubscriptionUpdateModel,
} from '../types';
import { determineAvailableRoutes, orderByTextCodeAsc } from '../helpers';
import { mapRouteInformationToRouteDetails } from '../maps/mapRouteInformationToRouteDetails';
import { mapRouteSubscriptionsToSavedView, mapUpdateRouteSubscriptionToApi } from '../maps';

provideApolloClient(apolloClient);

export const useRouteSubscriptionStore = defineStore('routeSubscriptionStore', () => {
  const subscriptions = ref<Array<RouteSubscription> | undefined>(undefined);
  const isSubscriptionLoading = ref<boolean>(false);
  const isSubscriptionAdding = ref<boolean>(false);
  const isSubscriptionUpdating = ref<boolean>(false);
  const isSubscriptionDeleting = ref<boolean>(false);
  const isSubscriptionFetchError = ref(false);
  const isSubscriptionUpdateError = ref(false);
  const isSubscriptionDeleteError = ref(false);

  const {
    allRoutes,
    allRoutesError: isRoutesError,
    isLoading: isRoutesLoading,
  } = storeToRefs(useRoutesStore());

  const isLoading = computed(() =>
    isRoutesLoading.value
    || isSubscriptionLoading.value
    || isSubscriptionUpdating.value
    || isSubscriptionDeleting.value
    || isSubscriptionAdding.value);

  const availableRoutes = computed(() =>
    determineAvailableRoutes({
      allRoutes: allRoutes.value || [],
      subscriptions: subscriptions.value || [],
    }).sort(orderByTextCodeAsc));

  const subscriptionsView: Ref<RouteSubscriptionSavedModel[] | undefined> = computed(() => {
    if (!subscriptions.value) {
      return undefined;
    }
    return mapRouteSubscriptionsToSavedView({
      allRoutes: allRoutes.value || [],
      subscriptions: subscriptions.value,
    });
  });

  const allRoutesView: Ref<RouteDetail[]> = computed(
    () => allRoutes.value?.map(mapRouteInformationToRouteDetails) || [],
  );

  const getSubscriptions = async () => {
    const {
      refetch,
      onResult,
      onError,
      loading,
    } = useQuery(
      getRouteSubscriptions,
    );

    watch(() => loading.value, (newValue) => {
      isSubscriptionLoading.value = newValue;
    }, { immediate: true });

    onError(() => {
      isSubscriptionFetchError.value = true;
    });

    onResult((queryResult: ApolloQueryResult<GetRouteSubscriptionsResponse>) => {
      isSubscriptionFetchError.value = false;
      subscriptions.value = queryResult?.data?.routeSubscriptions || [];
    });

    await refetch();
  };

  const deleteSubscription = async ({
    textCode,
    onApiError,
    onApiSuccess,
  }: {
    textCode: string;
    onApiError?: () => void;
    onApiSuccess?: () => void;
  }) => {
    const {
      mutate: callDeleteRouteSubscription,
      onError,
      onDone,
      loading,
    } = useMutation(
      deleteRouteSubscription,
    );

    isSubscriptionDeleteError.value = false;
    watch(() => loading.value, (newValue) => {
      isSubscriptionDeleting.value = newValue;
    });

    onError(() => {
      isSubscriptionDeleteError.value = true;
      if (onApiError) {
        onApiError();
      }
    });

    onDone(() => {
      if (onApiSuccess) {
        onApiSuccess();
      }
    });

    callDeleteRouteSubscription({
      deleteRouteSubscriptionInput: {
        textCode,
      },
    });
  };

  const updateSubscription = async ({
    routeSubscription,
    onApiError,
    onApiSuccess,
  } : {
    routeSubscription: RouteSubscriptionUpdateModel;
    onApiError?: () => void;
    onApiSuccess?: () => void;}) => {
    const {
      mutate: updateRouteSubscription,
      onError,
      onDone,
      loading,
    } = useMutation(
      upsertRouteSubscription,
    );

    isSubscriptionUpdateError.value = false;
    const apiModel = mapUpdateRouteSubscriptionToApi({
      routeSubscription,
      allRoutes: allRoutesView.value,
    });

    watch(() => loading.value, (newValue) => {
      isSubscriptionUpdating.value = newValue;
    });

    onError(() => {
      isSubscriptionUpdateError.value = true;
      if (onApiError) {
        onApiError();
      }
    });

    onDone(() => {
      if (onApiSuccess) {
        onApiSuccess();
      }
    });

    updateRouteSubscription({
      upsertRouteSubscriptionInput: apiModel,
    });
  };

  const addSubscription = async ({
    routeSubscription,
    onApiError,
    onApiSuccess,
  } : {
    routeSubscription: RouteSubscriptionUpdateModel;
    onApiError?: () => void;
    onApiSuccess?: () => void;}) => {
    const {
      mutate: updateRouteSubscription,
      onError,
      onDone,
      loading,
    } = useMutation(
      upsertRouteSubscription,
    );

    isSubscriptionUpdateError.value = false;
    const apiModel = mapUpdateRouteSubscriptionToApi({
      routeSubscription,
      allRoutes: allRoutesView.value,
    });

    watch(() => loading.value, (newValue) => {
      isSubscriptionAdding.value = newValue;
    });

    onError(() => {
      isSubscriptionUpdateError.value = true;
      if (onApiError) {
        onApiError();
      }
    });

    onDone(() => {
      if (onApiSuccess) {
        onApiSuccess();
      }
    });

    updateRouteSubscription({
      upsertRouteSubscriptionInput: apiModel,
    });
  };

  return {
    availableRoutes,
    isRoutesError,
    subscriptions: subscriptionsView,
    allRoutes: allRoutesView,
    isLoading,
    isSubscriptionLoading,
    isSubscriptionAdding,
    isSubscriptionUpdating,
    isSubscriptionFetchError,
    isSubscriptionDeleteError,
    isSubscriptionUpdateError,
    getSubscriptions,
    deleteSubscription,
    addSubscription,
    updateSubscription,
  };
});
