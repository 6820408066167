import { provideApolloClient, useQuery } from '@vue/apollo-composable';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getVesselsServedByVesselCodeQuery } from './queries/vessel';
import { apolloClient } from '../services/ApolloClient';
import { Vessel } from '../apiTypes';
import { mapRoutesToMapRoutes, mapRouteToRouteServed } from '../maps';
import { RoutesServedType } from '../types/RenderTypes';

provideApolloClient(apolloClient);

const vesselsServed = ref<Vessel>();
const isLoading = ref<boolean>(false);

const processResult = ((queryResult, document) => {
  if (document.value.loc?.source.body === getVesselsServedByVesselCodeQuery.loc?.source.body) {
    vesselsServed.value = queryResult.data?.vessels[0];
  }
});

export const vesselStoreDefinition = {
  state: () => ({
    vesselsServed,
    isLoading,
  }),
  actions: {
    async requestVesselsServed(vesselCode: string) {
      const {
        refetch,
        onResult,
        onError,
        loading,
        document,
      } = useQuery(getVesselsServedByVesselCodeQuery, {
        vesselCode,
      });
      isLoading.value = loading.value;

      onError((error) => {
        isLoading.value = loading.value;
        console.log('requestVesselsServed: error', error);
      });

      onResult((queryResult) => {
        isLoading.value = loading.value;
        processResult(queryResult, document);
      });

      await refetch();
    },
  },
  getters: {
    getVesselsServed() {
      return vesselsServed.value?.routes
        ? vesselsServed.value.routes.map((route) => ({
          ...mapRouteToRouteServed(route),
          routeMap: [mapRoutesToMapRoutes(route)],
        } as RoutesServedType))
        : undefined;
    },
  },
};

export const useVesselStore = defineStore('vesselStore', vesselStoreDefinition);
