import { Port } from '../types/RenderTypes';

export const orderRoutesByOriginPortAsc = (
  a: { ports: Port[] },
  b: { ports: Port[] },  
) => {
  if (a.ports?.[0]?.name < b.ports?.[0]?.name) {
    return -1;
  }
  if (a.ports?.[0]?.name > b.ports?.[0]?.name) {
    return 1;
  }
  return 0;
};
