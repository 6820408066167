import {
  createRouter, createWebHashHistory, RouteRecordRaw,
} from 'vue-router';
import ServiceStatus from '../views/ServiceStatus.vue';
import DummyView from '../views/DummyView.vue';
import TimetableSearch from '../views/TimetableSearch.vue';
import SmsApp from '../views/SmsApp.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/service-status',
    name: 'servicestatus',
    component: ServiceStatus,
  },
  {
    path: '/timetable-search',
    name: 'timetableSearch',
    component: TimetableSearch,
  },
  {
    path: '/sms',
    name: 'sms',
    component: SmsApp,
  },
  {
    path: '/timetable-search/:from/:to/:departureDate',
    name: 'timetableResult',
    component: DummyView,
    props: (route) => ({
      from: route.query.from,
      to: route.query.to,
      departureDate: route.query.departureDate,
    }),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
