import { AccountInfo } from '@azure/msal-browser';
import { maxBy } from './maxBy';

const accountIsActive = (mostRecentAccount: AccountInfo): boolean => {
  if (!mostRecentAccount?.idTokenClaims?.exp) {
    return true;
  }
  // exp is in seconds since epoch
  const expiryDate = new Date(mostRecentAccount.idTokenClaims.exp * 1000);

  return (expiryDate.getTime() >= new Date().getTime());
};

export const getActiveAccount = (accounts: AccountInfo[]): AccountInfo | undefined => {
  const mostRecentAccount = maxBy(accounts, (account: AccountInfo) => account.idTokenClaims?.exp);

  if (!mostRecentAccount) {
    return undefined;
  }

  if (!accountIsActive(mostRecentAccount)) {
    return undefined;
  }

  return mostRecentAccount;
};
