import { Activity, Vessel } from './api';

export interface Center {
  lat: number;
  lng: number;
}

export interface LatLng {
  lat: number;
  lng: number;
}

export interface Options {
  position: LatLng;
}

export interface MapBounds {
  north: number;
  east: number;
  south: number;
  west: number;
}
export interface MapPort {
  name: string;
  options: Options;
  portCode?: string;
  image?: string;
}

export interface MapLocation {
  name: string;
  options: Options;
  code?: string;
  image?: string;
}

export interface Port {
  name: string;
  portCode?: string;
  latitude?: number;
  longitude?: number;
  order?: number;
  location?: string;
  portUrl?: string;
}

export interface RecentOrSuggestedSearchEntry {
  ports: Port[];
  location?: string;
  routeId: string;
  routeCode?: string;
  textCode?: string;
  iconName: string;
}
export interface RecentOrSuggestedSearchEntryGroup {
  ports: RecentOrSuggestedSearchEntry[];
  location?: string;
}

export interface MapOptions {
  path: LatLng[];
  geodesic: boolean;
  strokeColor: string;
  strokeOpacity: number;
  strokeWeight: number;
}

export interface GoogleMapsRoute {
  name: string;
  status?: string;
  ports: Port[];
  options: MapOptions;
  code?: string;
  location?: string;
  originPort?: Port;
  destinationPort?: Port;
}

export interface IOfflineTimetable {
  title: string;
  portFrom?: string;
  portTo?: string;
  dateFrom?: Date;
  dateTo?: Date;
  timetablePdfUrl: string;
  timetableImageUrl: string;
  dateAdded?: Date;
  lastUpdated: string;
  key: string;
}

export interface RouteInfo {
  icon: string;
  title: string;
  text: string;
}
export interface CarouselDateItemProps {
  carouselItemDate: string;
  active: boolean;
  disabled: boolean;
  dayOfWeek: string;
  dayOfMonth: string;
  month: string;
  onClick?: ()=>void;
}
export interface ServiceStatusKeyItem {
  iconName: string;
  heading: string;
  text: string;
}
export enum SuggestionPortType {
  Origin = 0,
  Destination = 1,
}

export enum OfflineTimetableDownloadStatus {
  NotDownloaded = 'Not downloaded',
  DownloadStarted = 'Download started',
  Downloaded = 'Downloaded',
  Error = 'Error',
}

export interface IOfflineTimetableWithStatus extends IOfflineTimetable {
  downloadStatus: OfflineTimetableDownloadStatus;
  storagePath?: string;
}

export interface RoutesServedType {
  routeCode: string;
  timetableLink?: string;
  keyInformation: string;
  portList: Array<Port>;
  vesselList: Array<Vessel>;
  routeMap?: Array<GoogleMapsRoute>;
  mapPort: Array<MapPort>;
}

export interface InteractiveMapPanelViewModel {
  title: string;
  description: string;
  soundbiteUrl?: string;
  activities?: Array<Activity>;
  address?: string;
  addressTitle?: string;
  linkText?: string;
  linkUrl?: string;
  routesServed?: Array<RoutesServedType>;
}
