import { MapBounds } from '..';

const getMapBounds = (lats: Array<number>, lngs: Array<number>) => ({
  north: Math.max(...lats),
  east: Math.max(...lngs),
  south: Math.min(...lats),
  west: Math.min(...lngs),
} as MapBounds);

export { getMapBounds };
