import { SailingStatusType } from '../types';

export const mapSailingStatusToTimeTableStatusType = (value) => {
  switch (value) {
    case 'DELAYED':
      return SailingStatusType.Delayed;
    case 'CANCELLED':
      return SailingStatusType.Cancelled;
    default:
      return SailingStatusType.Normal;
  }
};
