import { Port } from '../types/api';

export const mapPortToLocation = (port: Port) => ({
  name: port.name,
  options: {
    position: { lat: port.latitude, lng: port.longitude },
  },
  location: port.location?.name,
  portUrl: port.portUrl,
});
