import { RouteSubscriptionSavedModel } from '@components/types';

const emptyRouteSubscription: RouteSubscriptionSavedModel = {
  textCode: '',
  startDate: '',
  endDate: '',
  id: '',
};

export const skeletonSubscriptions: RouteSubscriptionSavedModel[] = [
  emptyRouteSubscription,
  emptyRouteSubscription,
];
