import { parseISO, subDays } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { dateIsLessThanCurrentTime } from '../dateTimeUtilities';
import { RouteSubscription } from '../../types/api';
import { timezone } from '../../envVariables';

export const filterOutExpiredSubscriptionsFromApi = (
  subscriptions: RouteSubscription[],
  currentDate = new Date(),
)
: RouteSubscription[] => (subscriptions?.filter(
  (subscription: RouteSubscription) => {
    const prevDay = subDays(parseISO(subscription.endDate), 1);
    const prevDayUk = toZonedTime(prevDay, timezone);
    currentDate.setHours(0, 0, 0, 0);

    return !dateIsLessThanCurrentTime(prevDayUk, currentDate);
  },
)) || [];
