import { Location } from '../types/api';

export const mapLocationToDestination = (item: Location) => ({
  name: item.name,
  options: {
    position: { lat: item.latitude, lng: item.longitude },
  },
  code: item.locationCode,
  image: item.locationImage,
});
